import React from "react";
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from "react-bootstrap";
// import bannerImage from './../images/banner.jpeg';


function Navigation() {
    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="md">
                <Navbar.Brand href="/">
                    <b>Cannabis Info Central (Demo)</b>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <NavLink to="/">Home</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/about">About</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/contact">Contact</NavLink>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            {/* <div className="container-fluid px-0">
                <div className="row">
                    <img src={bannerImage} alt="placeholder 960" className="img-fluid w-100" />
                </div>
            </div> */}
        </div>

    );
}

export default Navigation;

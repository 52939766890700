import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Navigation from "./components/Navigation";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from './components/Home';
import {Row, Col } from 'react-bootstrap';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Navigation />
        <div className="container-fluid main-content">
          <Row className="justify-content-md-center">
            <Col xs lg="8">
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                {/* <Route component={Error}/> */}
              </Switch>
            </Col>

          </Row>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import LocalCredentialsBanner from "./../services/helpers/LocalCredentialsBanner";
import MockDataWarning from "./../services/helpers/MockDataWarning";
import Search from "./../search/Search";
import { kendra, indexId, errors } from "./../services/Kendra";
import { facetConfiguration } from "./../search/configuration";
import Card from "react-bootstrap/esm/Card";

function Home() {
    return (
        <Card>
            <Card.Header>Search</Card.Header>
            <Card.Body>
                <div className="App">
                    {errors.length > 0 ? (
                        <MockDataWarning errors={errors} />
                    ) : (
                            <LocalCredentialsBanner />
                        )}
                    <Search kendra={kendra} indexId={indexId} facetConfiguration={facetConfiguration} />
                </div>
            </Card.Body>
        </Card>
    );
}

export default Home;

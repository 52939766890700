/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:edfe6a5c-a0d5-480a-a8dc-0ea2a56ed2ff",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_5Q1qpxrr0",
    "aws_user_pools_web_client_id": "p7vrak7bf4code0ckgeb79okb",
    "oauth": {},
    "aws_content_delivery_bucket": "cannabis-packaging-20200909170022-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d100kj1wrd41t7.cloudfront.net"
};


export default awsmobile;

import React from "react";
import { Card } from "react-bootstrap";


function About() {
  return (
    <Card>
    <Card.Header>About Us</Card.Header>
    <Card.Body>
    <div>
        <p>About Us page body content</p>
    </div>
    </Card.Body>
    </Card>
  );
}

export default About;

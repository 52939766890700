import Kendra from "aws-sdk/clients/kendra";
import AWS from 'aws-sdk';


// // Initialize the Amazon Cognito credentials provider
AWS.config.region = 'us-west-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-2:edfe6a5c-a0d5-480a-a8dc-0ea2a56ed2ff',
    RoleArn: 'arn:aws:iam::444415590824:role/amplify-cannabis-packaging-develop-155539-unauthRole'
});

export const errors = [];

export const indexId = '6eadcf32-fdbe-493b-a779-c5c49a77bcdd';

export const kendra = new Kendra();